import * as React from "react"
import {
  ChakraProvider,
  Box,
  Image,
  VStack,
  theme,
} from "@chakra-ui/react"
import somethingisbrewing from "./somethingisbrewing.png"


export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <VStack minH="100vh" justify="center">
        <Image  src={somethingisbrewing} alt='HackerDAO' />
      </VStack>
    </Box>
  </ChakraProvider>
)
